@import "~styles/_variables";

.job-row-info-card {
  &__icon-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__icon-row {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &-title {
      size: 12px;
      font-weight: 500;
      color: $ash;

      &.job-id {
        display: flex;
        gap: 8px;
        align-items: center;
      }

      .copy-icon {
        cursor: pointer;

        &:hover {
          color: $inc-blue-new;
        }
      }
    }
  }
}