@import "~styles/_variables";

.spark-logo-btn {
  &__link {
    img {
      width: 16px;
    }

    &.disabled-link {
      filter: grayscale(100%);

      button {
        cursor: not-allowed;
      }
    }
  }

  &__history {
    &-icon {
      width: 16px;
      height: 16px;

      img {
        width: 16px;
        height: 16px;
      }

      border: 1px solid $light-gray;
      border-radius: 2px;
      padding: 1px;
    }

    &-button {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
