@import '~styles/variables';

.connect-steps-modal-content__wrapper {



    .steps-titles {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
    }

    .download-button {
        margin-left: 10px;
        display: inline-block;
    }

    p {
        font-size: 14px;
        line-height: 21px;
        color: $ash;
    }

    ol {
        padding-left: 16px;
    }

    li {
        h2 {
            font-size: 14px;
            line-height: 16px;
            color: $charcoal;
            margin-bottom: 12px;
        }

        p,
        label {
            font-size: 14px;
            line-height: 18px;
            color: $ash;
            margin-bottom: 8px;
        }
    }
}