@import '~styles/variables';

.status-card-list__wrapper {
  display: flex;
  gap: 8px;
  margin: 4px;
  justify-content: flex-end;
  align-items: center;

  >label {
    margin-inline-end: 8px;
    font-size: 14px;
    line-height: 21px;
  }
}

.status-card {
  &__wrapper {
    flex-grow: 1;
    flex-basis: 220px;
    max-height: 66px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: unset;

    border-block-start: 2px solid;
    border-start-start-radius: 0px;
    border-start-end-radius: 0px;

    .ant-statistic-title {
      color: $ash;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: start;
    }

    .ant-statistic-content {
      color: $charcoal;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: start;
    }

    .ant-statistic-skeleton {
      padding: 0;
    }

    .ant-skeleton-title {
      margin: 0;
    }

    &--in-queue {
      border-block-start-color: map-get($job-status, in-queue);
    }

    &--running {
      border-block-start-color: map-get($job-status, running);
    }

    &--succeeded {
      border-block-start-color: map-get($job-status, succeeded);
    }

    &--failed {
      border-block-start-color: map-get($job-status, failed);
    }

    &--cancelled {
      border-block-start-color: map-get($job-status, cancelled);
    }

    &--timed-out {
      border-block-start-color: map-get($job-status, timed-out);
    }
  }
}