@mixin header-style($font-size, $font-weight, $line-height, $letter-spacing) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

.header {
  &__wrapper {
    text-align: start;
    margin-block: 16px;
  }

  &__first-line {
    @include header-style(14px, 400, 16px, 0px);
    margin-block-end: 4px;
  }

  &__second-line {
    @include header-style(20px, 400, 23px, 0px);
  }
}