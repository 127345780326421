.language-changer {
  &__wrapper {
    width: calc(100% - 16px);
    margin-inline: 8px;

    .ant-select-selector {
      border-radius: 85px;
    }
  }

  &__language-item {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__popup {
    .language-changer__language-icon {
      display: none;
    }
  }
}