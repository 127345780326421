@import '~styles/_variables';

.jobs-table {
  &__title {
    margin-block: 24px 12px;
    text-align: start;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;

    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &-sync {
      color: $ash;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0px;

      display: flex;
      gap: 8px;
      align-items: baseline;
    }

    &-sync-btn {
      background: transparent;
    }
  }

  &__actions {
    &-wrapper {
      display: flex;
      gap: 8px;

      .anticon:hover {
        color: $inc-blue-new;
      }

      .anticon:active {
        background-color: $white;
      }
    }

    &-info:hover {
      cursor: pointer;
    }
  }

  &__job-id {
    width: 180px;
  }

  &__table-name-link {
    color: $inc-black;
    text-transform: uppercase;
    text-decoration: underline;
    display: block;

    &:hover,
    &:focus,
    &:active {
      color: $inc-blue;
      text-decoration: underline;
    }
  }

  &__schema-name-cell {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__mv-language-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__hoverable-actions {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline-end: 8px;
  }
}
