@import '~styles/_variables';

.hover-action {
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__btn-wrapper {
    &--hide {
      visibility: hidden;
      cursor: pointer;

      :hover {
        background-color: $brushed-sandstone;
        border-radius: 50%;
      }
    }
  }
}

.searchable-table__component {
  .ant-table-row:hover {
    .hover-action__btn-wrapper--hide {
      visibility: visible;
    }
  }
}
