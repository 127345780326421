@import '~styles/_variables';

.jobs-table-details {
  &__wrapper {
    padding-block-end: 16px;
  }

  &__title {
    margin-block: 24px 12px;
    text-align: start;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;

    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &-table-name {
      text-transform: uppercase;
    }

    &-sync {
      color: $ash;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0px;

      display: flex;
      gap: 8px;
      align-items: baseline;
    }

    &-sync-btn {
      background: transparent;
    }
  }

  &__pre-content {
    display: flex;
    align-items: center;
    gap: 4px;

    &-icon {
      color: $slate-gray;

      &:hover,
      &:focus,
      &:active {
        color: $inc-blue;
        cursor: pointer;
      }
    }

    &-text {
      color: $ash;
      font-size: 16px;

      &-bold {
        font-weight: 600;
      }
    }
  }

  &__mv-language-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__hoverable-action-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__hoverable-actions {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline-end: 8px;
  }
}
