@import '~styles/variables';

.status-cell-renderer {
  &__wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100px;
  }

  &-icon {
    &--in-queue {
      color: map-get($job-status-icon, in-queue);
    }

    &--running {
      color: map-get($job-status-icon, running);
    }

    &--succeeded {
      color: map-get($job-status-icon, succeeded);
    }

    &--cancelled {
      color: map-get($job-status-icon, cancelled);
    }

    &--failed {
      color: map-get($job-status-icon, failed);
    }

    &--timed-out {
      color: map-get($job-status-icon, timed-out);
    }
  }
}