@import "~styles/variables.scss";

.date-cell-renderer {
  &__creation-time-column,
  &__updated-time-column {
    &-wrapper {
      width: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &-date {
      font-weight: 400;
      font-size: 14px;
      color: $ash;
    }
  }

  &__creation-time-column-header,
  &__updated-time-column-header {
    display: flex;
    justify-content: center;
  }
}
