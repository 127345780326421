@import '~styles/variables';

.user-profile {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &__button {
    width: calc(100% - 7px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .ant-btn-icon {
      border: 1px solid $dark-gray;
      border-radius: 50%;
    }
  }

  &__menu-item-icon {
    margin-inline-end: 8px;
  }
}
