@import '~styles/variables';

.settings-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &__button {
    width: calc(100% - 7px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__modal {
    &-title{
      margin-bottom: 20px;
    }
  }
}
