@import '~styles/variables';

.safeGuard {
  &__table {
    height: 250px;
    max-height: 250px;
    overflow-y: auto;
  }

  button.ant-btn-primary {
    background-color: $inc-blue--hover;
    border-color: $inc-blue-new;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button.ant-btn-primary:not(:disabled):hover {
    background-color: $inc-blue;
    border-color: $inc-blue-new;
  }

  &__add {
    margin-bottom: 4px;
  }
}
