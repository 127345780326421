@import '~styles/variables';

.config-form {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .ant-switch-checked {
    background-color: $inc-blue--hover;
  }

  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background-color: $inc-blue;
  }

  .ant-switch.ant-switch-checked:focus:not(.ant-switch-disabled) {
    box-shadow: 0 0 0 2px rgba(29, 59, 226, 0.2);
  }

  &__border-bottom {
    border-bottom: 1px solid $light-gray;
    margin-bottom: 12px;
  }

  &__section {
    &-switch {
      .ant-form-item {
        margin-bottom: unset;
      }

      .ant-form-item-control {
        align-items: flex-end;
      }
    }


    &-headers {
      display: flex;
      justify-content: space-between;

      &-titles {
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 16px;
        color: $ash;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    &-button {
      background-color: $inc-blue--hover;
      border-color: $inc-blue-new;
    }

    &-button.ant-btn-primary:not(:disabled):hover,
    &-button.ant-btn-primary:not(:disabled):focus {
      background-color: $inc-blue;
      border-color: $inc-blue-new;
    }
  }
}
