.chidori__loading-spinner {
  animation: spinning 1s linear infinite;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}