@import "~styles/variables.scss";

.searchable-table-bar {
  &__wrapper {
    padding: 16px;
    display: grid;
    grid-template-columns: 4fr 2fr 2fr 1fr;
    gap: 16px;
    align-items: baseline;

    &.all-jobs {
      grid-template-columns: repeat(3, 2fr) repeat(2, 1fr);
    }
  }

  &__status-select-wrapper {
    .ant-select-selection-placeholder {
      text-align: start;
    }
  }

  &__checkbox {
    width: 100%;
  }

  &__status-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  &__icon {
    &--in-queue {
      color: map-get($job-status-icon, in-queue);
    }

    &--running {
      color: map-get($job-status-icon, running);
    }

    &--succeeded {
      color: map-get($job-status-icon, succeeded);
    }

    &--cancelled {
      color: map-get($job-status-icon, cancelled);
    }

    &--failed {
      color: map-get($job-status-icon, failed);
    }

    &--timed-out {
      color: map-get($job-status-icon, timed-out);
    }
  }
}
