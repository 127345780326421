@import '~styles/_variables';

.code-snippet {
  &__wrapper {
    position: relative;
  }

  &__copy-btn,
  &__copy-success {
    position: absolute;
    right: 0;
    top: 0;
    margin: 4px;
    padding: 2px;
  }

  &__copy-btn {
    color: $white;
  }

  &__copy-success {
    color: $success;
  }
}