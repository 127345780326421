.connect-page-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  .connect-content-wrapper {
    flex-grow: 1;
    display: flex;
    overflow: auto;
  }

}