@import '~styles/variables';

.cloud__container[data-page="single-sign-on"] {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block-start: 96px;

  .username-input,
  .password-input,
  button {
    height: 48px;
  }

  @keyframes myanimation {
    0% {
      transform: rotate(-120deg);
      position: relative;
    }


    100% {
      transform: rotate(0deg);
      position: relative;
    }
  }
}

[data-page="single-sign-on"] .incorta-logo {
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    svg {
      width: 300px;
    }
  }

  &__circle {
    animation: myanimation 0.5s ease-out;
  }

  &__name {
    width: 175.48px;
  }
}

[data-page="single-sign-on"] .cloud-main {
  display: flex;
  justify-content: center;

  &__slogan {
    text-align: center;
    margin-block-start: 30px;
  }
}

.cloud-main-section {
  margin-top: 32px;
  width: 496px;
}

[data-page="single-sign-on"] .cloud-main__title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

form#sign-in {
  margin-block-start: 24px;
}

.cloud__container[data-page="single-sign-on"] .error-label {
  margin-top: 24px;
  color: $inc-red;
  height: 16px;
  font-size: 14px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-weight: 600;
  line-height: 16px;
}

.cloud__container[data-page="single-sign-on"] .ant-form {

  label.email-field__label,
  label.password-field__label {
    font-size: 14px;
    font-weight: 500;
  }

  .ant-form-item.button {
    margin-block-end: 42px;
  }
}

.cloud__container[data-page="single-sign-on"] {

  .ant-form-item.username .ant-form-item-explain-error,
  .ant-form-item.password .ant-form-item-explain-error {
    text-align: start;
    margin-inline-start: 16px;
    font-size: 12px;
  }
}

[data-page="single-sign-on"] .forgot-password {
  display: flex;
  justify-content: flex-end;
}

[data-page="single-sign-on"] .forgot-password label {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: $slate-gray;
  cursor: pointer;

  &.disabled-link {
    cursor: default;
  }
}

[data-page="single-sign-on"] .submit-form-button {
  background-color: $inc-blue-new;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.01em;

  &:hover {
    background-color: $inc-blue--hover;
  }
}

.language-choices {
  &__wrapper {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  &__item {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: $inc-blue-new;
    }
  }
}

.language-choices {
  &__wrapper {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  &__item {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: $inc-blue-new;
    }
  }
}

[data-page="single-sign-on"] .submit-form-button__wrapper {
  margin: 32.15px 0 42px 0;
}

.sign-in__footer {
  height: 100%;
  padding-block-end: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 26px;
  color: $ash;
  text-align: center;

  &-logo {
    display: block;
    margin-block-start: 8px;
  }
}

@media screen and (max-width: 900px) {
  .cloud-main-section {
    width: 400px;
  }

  [data-page="single-sign-on"] .footer {
    display: none;
  }

  [data-page="single-sign-on"] .terms-agreement__wrapper {
    max-width: 496px;
  }
}

@media screen and (max-width: 575px) {
  [data-page="single-sign-on"] .cloud-main {
    align-items: center;
  }

  [data-page="single-sign-on"] .cloud-main__title {
    font-size: 20px;
  }

  [data-page="single-sign-on"] .submit-form-button {
    font-size: 14px;
  }
}