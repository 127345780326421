@import '~styles/_mixins';
@import '~styles/variables';

.job-history {
  &__card-element {
    border-color: $new-brand-color;
    height: 120px;
  }

  &__chart-wrapper {
    width: 100%;
    padding: 16px;
    background-color: $white;
  }

  &__chart-select {
    width: 150px;

    &-label {
      display: flex;
      gap: 4px;
      align-items: baseline;
    }
  }

  &__chart {
    height: 500px;
    width: 100%;

    div:has(.job-history__chart-content) {
      border-block-start: 4px solid #8ba3f4 !important;
      padding: 6px 4px 4px 4px !important;
    }

    &-item-icon {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #8ba3f4;
    }

    &-line {
      display: flex;
      align-items: center;
      gap: 4px;

      &.table-name {
        text-transform: uppercase;
      }
    }

    &-property-name {
      color: $ash;
      font-size: 12px;
    }

    &-property-value {
      color: $charcoal;
      font-size: 12px;

      &.table-name {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &__job-info-card {
    margin-block-start: 16px;
    border-color: $light-gray;

    .ant-card-head {
      border-bottom: unset;
    }

    .ant-card-body {
      padding: unset;
    }

    &-title {
      display: flex;
      font-size: 16px;
    }

    &-body {
      display: grid;
      grid-template-columns: repeat(6, minmax(100px, 1fr));
      padding: 16px;
    }

    &-actions {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      border-block-start: 1px solid $solitude-gray;

      @media all and (max-width: 765px) {
        grid-template-columns: 1fr;
      }
    }

    &-action-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-inline: 10px;

      .spark-logo-btn__link {
        flex: 1;
        display: flex;
        justify-content: center;
      }

      &:nth-child(n + 2):not(&:nth-last-child(1)) {
        border-inline: 1px solid $solitude-gray;
      }

      &:first-child {
        border: unset;
        border-inline-end: 1px solid $solitude-gray;
      }

      &:last-child {
        border: unset;
        border-inline-start: 1px solid $solitude-gray;
      }

      &.download-logs {
        display: flex;
        gap: 4;
      }

      &.extra-icon {
        margin-left: flex-end;
      }

      .spark-logo-btn__history-icon {
        border: unset;
      }
    }

    &-property-name {
      font-size: 14px;
      font-weight: 500;
      color: $ash;
      text-align: start;
    }

    &-property-value {
      font-size: 16px;
      color: $charcoal;
      text-align: start;
    }
  }
}
