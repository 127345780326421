@import '~styles/variables';

.navigation-layout {
  &__wrapper {
    min-height: 100%;
    max-height: 100vh;
  }

  &__menu-wrapper {
    &.navigation-layout__menu-wrapper.ant-menu-inline-collapsed {
      border-inline-end: unset;
    }

    .ant-menu-title-content,
    .navigation-layout__menu-item-wrapper {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__sider {
    height: 100vh;

    &-logo {
      display: flex;
      padding: 16px 0 24px 16px;
      img {
        height: 19px;
        width: 107px;
        transition: width 0.2s ease-out;
      }

      &.collapsed {
        padding-inline-start: unset;
        justify-content: center;
        img {
          width: 32px;
        }
      }
    }

    .ant-layout-sider-children {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px;
      padding-block-end: 60px;

      ul {
        height: 100vh;

        &.ant-menu-light.ant-menu-root.ant-menu-inline {
          border: unset;
        }
      }
    }

    &.ant-layout-sider.ant-layout-sider-has-trigger .ant-layout-sider-trigger {
      background-color: $brushed-sandstone;
    }

    &-actions {
      display: flex;
      flex-direction: column;
      gap: 12px;
      animation: slowAppear 0.4s ease forwards;
    }
  }

  &__logo {
    width: 80%;
  }

  &__main {
    background-color: $gray-f5;
    overflow: scroll;
  }

  &__menu-item-wrapper {
    display: flex;

    :hover {
      cursor: pointer;
    }
  }

  &__spark-history-link {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-inline-start: 15px;
    margin-block-end: 12px;
    margin-inline-end: 8px;

    section:first-child {
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 16px;
    }

    &-label {
      color: $inc-black;
      text-decoration: underline;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        color: $inc-blue;
        text-decoration: underline;
      }
    }
  }

  &__content {
    margin-inline: 24px;
    margin-block-end: 24px;
  }
  &__connect-icon {
      width: 14px;
      height: 14px;
  }
  
}

@keyframes slowAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

