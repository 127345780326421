.timeout {
  .ant-form-item:last-child {
    margin-bottom: unset;
  }
  
  &__emails-select {
    .ant-select-selector {
      overflow-y: auto;
      max-height: 45px;
    }
  }

  &__inputs {
    display: flex;
    gap: 32px;
    .ant-form-item {
      margin-bottom: unset;
    }
  }
}
