.mv-language-renderer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  width: 180px;

  .icon {
    width: 16px;
    height: 16px;
  }
}
