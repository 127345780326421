.connect-form {
    max-width: 600px;

    &__allowed-schema {
        width: 100%;

        .ant-select-selector {
            overflow-y: auto;
            max-height: 45px;
        }
    }

    &__input {
        width: 100%;
    }

    &__select {
        width: 100%;
    }

    &__buttons {
        margin-top: 20px;
        text-align: right;
    }

    &__submit {
        margin-left: 10px;
    }
}
