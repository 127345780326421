$slate-gray: #848f99;
$black: #000000;
$inc-black: #231f20;
$inc-blue: #3600fa;
$inc-blue--hover: #474df0;
$inc-blue-new: #1d3be2;
$brushed-sandstone: #c8c7bb;
$charcoal: #393e41;
$light-gray: #cfd5da;
$ash: #5f6972;
$gray-f5: #f5f5f5;
$dark-gray: #434343;
$solitude-gray: #eef0f2;
$inc-red: #ee312d;
$white: #ffffff;
$success: #52c41a;
$green-tone-3: #469650;
$error: #f5222d;
$new-brand-color: #21314d;
$red-tone-2: #b43237;
$yellow-tone: #ff8200;
$green-tone-3: #469650;
$mid-tone-gray: #ddd;

/*=============================================
=            Jobs Statuses            =
=============================================*/
$job-status: (
  in-queue: #8ba3f4,
  running: $inc-blue--hover,
  succeeded: #8bb840,
  failed: #db302f,
  cancelled: #e38020,
  timed-out: #f4b400,
);
/*=====  End of Jobs Statuses  ======*/

/*=============================================
=        Job Statuses Icons in Table          =
=============================================*/
$job-status-icon: (
  in-queue: $slate-gray,
  running: $slate-gray,
  succeeded: $green-tone-3,
  failed: $red-tone-2,
  cancelled: $yellow-tone,
  timed-out: #f4b400,
);
/*=====  End of Job Statuses Icons in Table  ======*/
