@import '~styles/variables';

.connections-apps {
  width: 100%;
  padding-left: 10px;

  box-sizing: border-box;
}

.connected-app {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid $brushed-sandstone;
  background-color: $white;
  margin-bottom: 8px;

  .ant-list-item-meta {
    display: flex;
    align-items: center;


    .ant-list-item-meta-avatar {
      margin-right: 20px;
      margin-left: 20px;

    }

    .ant-list-item-meta-title {
      font-weight: 600;
      color: $inc-black;
      text-align: left;
    }

    .ant-list-item-meta-description {
      font-size: 10px;
      color: $dark-gray;
      padding-right: 20px;
      text-align: left;
      margin-right: 27px;

      .inline-link-button {
        padding: unset;
        margin: unset;
        background: transparent;
      }
    }
  }

  .actions-container {
    display: flex;
    align-items: center;
    margin-top: 16px;
    padding-bottom: 25px;
    margin-right: 80px;
  }

  .ant-btn {
    margin-right: 16px;
    border-radius: 8px;
    color: $inc-blue;
    background-color: $white;
  }

  .expanded-section {
    display: flex;
    justify-content: center;
    width: 100px;
    margin-top: 20px;
  }

  .action-button {
    color: $inc-blue;
    font-size: 16px;
  }
}