@import '~styles/_variables';

.metrics-layout {
  display: flex;
  flex-direction: column;

  &__filters {
    display: flex;
    gap: 12px;
    justify-content: flex-start;
    background-color: $white;
    padding: 16px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);

    &-range {
      width: 40%;
    }

    &-select {
      width: 300px;
      margin-left: 16px;
    }
  }

  &__chart {
    background-color: $white;
    border: 1px solid $light-gray;
    padding: 8px;
    margin-top: 16px;
  }
}
