@import '~styles/variables';

.connection__container {
  border-radius: 8px;
  text-align: left; 
  .body-text {
    font-size: 16px;
    color: $dark-gray;
    margin-bottom: 20px;
  }
}
.connection__divider{
  border-color: $black;
}